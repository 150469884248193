import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';

//Notifier
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { errorNotifier, successNotifier } from '../../util/notifications';


//API
import { request } from '../../util/fetchAPI';

//icons
import { BsExclamationCircle } from "react-icons/bs";
import { IoIosAddCircle } from "react-icons/io";
import { AiFillMinusCircle } from "react-icons/ai";
import { FaEdit } from "react-icons/fa";

// Reusable Collapsible Section Component
const CollapsibleSection = ({ title, sectionKey, expandedSections, toggleSection, children }) => (
    <div>
        <div
            className="Heading flex gap-2 hover:underline cursor-pointer"
            onClick={() => toggleSection(sectionKey)}
        >
            {expandedSections[sectionKey] ? <AiFillMinusCircle className="font-bold" /> : <IoIosAddCircle className="font-bold" />}
            {title}
        </div>

        {expandedSections[sectionKey] && <div className="mt-3">{children}</div>}
    </div>
);

export default function AssessmentFormView() {
    const { id } = useParams();
    const { register, setValue, handleSubmit, watch, reset, formState: { errors } } = useForm();

    const [expandedSections, setExpandedSections] = useState({});

    const toggleSection = (sectionKey) => {
        setExpandedSections(prev => ({
            ...prev,
            [sectionKey]: !prev[sectionKey]
        }));
    };

    const errorMessages = () => (
        <span className="flex items-center justify-start text-red-700 m-1">
            Required Field <BsExclamationCircle className="m-1 text-lg" />
        </span>
    );

    const PatientFormHandler = async (data) => {
        const payload = { ...data };

        try {
            const { data, status } = await request('/assessment/addAssessment', 'POST', { 'Content-Type': 'application/json' }, { ...payload });
            if (status === 200) {
                successNotifier(data.message);
                reset();
            } else {
                errorNotifier(data.message || "Failed to create case");
            }
        } catch (error) {
            console.error('Error adding patient:', error);
            alert('An error occurred while adding the patient');
        }
    };

    const fetchAssessmentData = async () => {
        try {
            const { data, status } = await request(`/assessment/getAssessment/${id}`, 'GET');

            if (status === 200) {
                Object.keys(data).forEach(key => setValue(key, data[key]));
            } else {
                errorNotifier(data.message || "Failed to fetch assessment data");
            }
        } catch (error) {
            console.error('Error fetching assessment data:', error);
            alert('An error occurred while fetching assessment data');
        }
    };


    useEffect(() => {
        fetchAssessmentData();
    }, [id, setValue]);

    return (
        <div className="FormContainer">
            <ToastContainer />
            <div className="Heading">
                Case Information
                </div>

            <div className="FormWrapper">
                <form onSubmit={handleSubmit(PatientFormHandler)} className="forms">

                    {/* Case No Field */}
                    <div className="InputBox">
                        <div>
                            <label htmlFor="caseId">Case No.#</label>
                            <input type="text" {...register("caseNo", { required: true })} className={errors.caseNo ? 'errorInput' : ""} readOnly />
                            {errors.caseNo && errorMessages()}
                        </div>
                    </div>

                    {/* Medical History Fields */}
                    <CollapsibleSection
                        title="Medical History"
                        sectionKey="medicalHistory"
                        expandedSections={expandedSections}
                        toggleSection={toggleSection}
                    >
                        <div>
                            <div className="InputBox">
                                <div>
                                    <label htmlFor="medicalCondition">Medical Condition</label>
                                    <input type="text" {...register("medicalCondition", { required: true })} className={errors.medicalCondition ? 'errorInput' : ""} />
                                    {errors.medicalCondition && errorMessages()}
                                </div>
                                <div>
                                    <label htmlFor="surgicalHistory">Surgical History</label>
                                    <input type="text" {...register("surgicalHistory")} className="" />
                                </div>
                            </div>

                            <div className='InputBox'>
                                <div>
                                    <label htmlFor="familyHistory">Family History</label>
                                    <input type="text" {...register("familyHistory", { required: false })} className={errors.familyHistory ? 'errorInput' : ""} />
                                    {errors.familyHistory && errorMessages()}
                                </div>

                                <div>
                                    <label htmlFor="medication">Medication</label>
                                    <input type="text" {...register("medication", { required: false })} className={errors.medication ? 'errorInput' : ""} />
                                    {errors.medication && errorMessages()}
                                </div>

                            </div>

                            <div className='InputBox'>
                                <div>
                                    <label htmlFor="allergies">Allergies</label>
                                    <input type="text" {...register("allergies", { required: false })} className={errors.allergies ? 'errorInput' : ""} />
                                    {errors.allergies && errorMessages()}
                                </div>
                            </div>
                        </div>
                    </CollapsibleSection>

                    {/* Chief Complaint Section */}
                    <CollapsibleSection
                        title="Chief Complaint"
                        sectionKey="chiefComplaint"
                        expandedSections={expandedSections}
                        toggleSection={toggleSection}
                    >
                        <div className='InputBox'>
                            <div>
                                <label htmlFor="primaryComplaint">Primary Complaint</label>
                                <input type="text" {...register("primaryComplaint", { required: true })} className={errors.primaryComplaint ? 'errorInput' : ""} />
                                {errors.primaryComplaint && errorMessages()}
                            </div>
                            <div>
                                <label htmlFor="duration">Duration</label>
                                <input type="text" {...register("duration", { required: false })} className={errors.duration ? 'errorInput' : ""} />
                                {errors.duration && errorMessages()}
                            </div>

                        </div>

                        <div className='InputBox'>
                            <div>
                                <label htmlFor="onset">Onset</label>
                                <input type="text" {...register("onset", { required: false })} className={errors.onset ? 'errorInput' : ""} />
                                {errors.onset && errorMessages()}
                            </div>

                            <div>
                                <label htmlFor="aggravatingFactor">Aggravating Factor</label>
                                <input type="text" {...register("aggravatingFactor", { required: false })} className={errors.aggravatingFactor ? 'errorInput' : ""} />
                                {errors.aggravatingFactor && errorMessages()}
                            </div>

                        </div>

                        <div className='InputBox'>
                            <div>
                                <label htmlFor="relievingFactor">Relieving Factor</label>
                                <input type="text" {...register("relievingFactor", { required: false })} className={errors.relievingFactor ? 'errorInput' : ""} />
                                {errors.relievingFactor && errorMessages()}
                            </div>

                        </div>
                    </CollapsibleSection>

                    {/* Physical Examinations Section */}
                    <CollapsibleSection
                        title="Physical Examinations"
                        sectionKey="physicalExaminations"
                        expandedSections={expandedSections}
                        toggleSection={toggleSection}
                    >
                        <div className='InputBox'>
                            <div><label htmlFor="posture">Posture</label>
                                <input type="text" {...register("posture", { required: false })} className={errors.posture ? 'errorInput' : ""} />
                                {errors.posture && errorMessages()}</div>

                            <div>
                                <label htmlFor="rangeOfMotion">Range of Motion</label>
                                <input type="text" {...register("rangeOfMotion", { required: false })} className={errors.rangeOfMotion ? 'errorInput' : ""} />
                                {errors.rangeOfMotion && errorMessages()}
                            </div>

                        </div>

                        <div className='InputBox'>
                            <div> <label htmlFor="muscleStrength">Muscle Strength</label>
                                <input type="text" {...register("muscleStrength", { required: false })} className={errors.muscleStrength ? 'errorInput' : ""} />
                                {errors.muscleStrength && errorMessages()}</div>

                            <div>
                                <label htmlFor="reflexes">Reflexes</label>
                                <input type="text" {...register("reflexes", { required: false })} className={errors.reflexes ? 'errorInput' : ""} />
                                {errors.reflexes && errorMessages()}
                            </div>

                        </div>

                        <div className='InputBox'>
                            <div>
                                <label htmlFor="sensation">Sensation</label>
                                <input type="text" {...register("sensation", { required: false })} className={errors.sensation ? 'errorInput' : ""} />
                                {errors.sensation && errorMessages()}
                            </div>
                        </div>
                    </CollapsibleSection>

                    <CollapsibleSection
                        title="Functional Assessments"
                        sectionKey="functionalAssessments"
                        expandedSections={expandedSections}
                        toggleSection={toggleSection}
                    >

                        <div className='InputBox'>
                            <div><label htmlFor="activitiesOfDailyLiving">Activities of Daily Living</label>
                                <input type="text" {...register("activitiesOfDailyLiving", { required: false })} className={errors.activitiesOfDailyLiving ? 'errorInput' : ""} />
                                {errors.activitiesOfDailyLiving && errorMessages()}</div>
                            <div>
                                <label htmlFor="mobility">Mobility</label>
                                <input type="text" {...register("mobility", { required: false })} className={errors.mobility ? 'errorInput' : ""} />
                                {errors.mobility && errorMessages()}
                            </div>

                        </div>

                        <div className='InputBox'>
                            <div>
                                <label htmlFor="balanceAndCoordination">Balance and Coordination</label>
                                <input type="text" {...register("balanceAndCoordination", { required: false })} className={errors.balanceAndCoordination ? 'errorInput' : ""} />
                                {errors.balanceAndCoordination && errorMessages()}
                            </div>
                            <div>
                                <label htmlFor="cognitiveFunctions">Cognitive Functions</label>
                                <input type="text" {...register("cognitiveFunctions", { required: false })} className={errors.cognitiveFunctions ? 'errorInput' : ""} />
                                {errors.cognitiveFunctions && errorMessages()}
                            </div>

                        </div>
                    </CollapsibleSection>


                    <CollapsibleSection
                        title="Special Test"
                        sectionKey="specialTest"
                        expandedSections={expandedSections}
                        toggleSection={toggleSection}
                    >

                        <div className='InputBox'>
                            <div>
                                <label htmlFor="orthopedicTests">Orthopedic Tests</label>
                                <input type="text" {...register("orthopedicTests", { required: false })} className={errors.orthopedicTests ? 'errorInput' : ""} />
                                {errors.orthopedicTests && errorMessages()}
                            </div>

                            <div>
                                <label htmlFor="neurologicalTests">Neurological Tests</label>
                                <input type="text" {...register("neurologicalTests", { required: false })} className={errors.neurologicalTests ? 'errorInput' : ""} />
                                {errors.neurologicalTests && errorMessages()}
                            </div>

                        </div>

                        <div className='InputBox'>
                            <div>
                                <label htmlFor="otherRelevantTests">Other Relevant Tests</label>
                                <input type="text" {...register("otherRelevantTests", { required: false })} className={errors.otherRelevantTests ? 'errorInput' : ""} />
                                {errors.otherRelevantTests && errorMessages()}
                            </div>

                        </div>
                    </CollapsibleSection>

                    {/* Treatment Goals Section */}
                    <CollapsibleSection
                        title="Treatment Goals"
                        sectionKey="treatmentGoals"
                        expandedSections={expandedSections}
                        toggleSection={toggleSection}
                    >  <div className='InputBox'>
                            <div>
                                <label htmlFor="shortTermGoals">Short Term Goals</label>
                                <input type="text" {...register("shortTermGoals", { required: false })} className={errors.shortTermGoals ? 'errorInput' : ""} />
                                {errors.shortTermGoals && errorMessages()}
                            </div>

                            <div>
                                <label htmlFor="shortTermGoals">Long Term Goals</label>
                                <input type="text" {...register("longTermGoals", { required: false })} className={errors.shortTermGoals ? 'errorInput' : ""} />
                                {errors.shortTermGoals && errorMessages()}
                            </div>

                        </div>

                        <div className='InputBox'>
                            <div>
                                <label htmlFor="patientExpectations">Patient Expectations</label>
                                <input type="text" {...register("patientExpectations", { required: false })} className={errors.patientExpectations ? 'errorInput' : ""} />
                                {errors.patientExpectations && errorMessages()}
                            </div>

                        </div>
                    </CollapsibleSection>

                    <CollapsibleSection
                        title="Physiotherapy Plans"
                        sectionKey="physiotherapyPlans"
                        expandedSections={expandedSections}
                        toggleSection={toggleSection}
                    >  <div className='InputBox'>
                            <div>
                                <label htmlFor="treatmentDuration">Treatment Duration</label>
                                <input type="text" {...register("treatmentDuration", { required: false })} className={errors.treatmentDuration ? 'errorInput' : ""} />
                                {errors.treatmentDuration && errorMessages()}
                            </div>

                            <div>
                                <label htmlFor="interventions">Interventions</label>
                                <input type="text" {...register("interventions", { required: false })} className={errors.interventions ? 'errorInput' : ""} />
                                {errors.interventions && errorMessages()}
                            </div>

                        </div>

                        <div className='InputBox'>
                            <div>
                                <label htmlFor="exercises">Exercises</label>
                                <input type="text" {...register("exercises", { required: false })} className={errors.exercises ? 'errorInput' : ""} />
                                {errors.exercises && errorMessages()}
                            </div>

                            <div>
                                <label htmlFor="electrotherapy">Electrotherapy</label>
                                <input type="text" {...register("electrotherapy", { required: false })} className={errors.electrotherapy ? 'errorInput' : ""} />
                                {errors.electrotherapy && errorMessages()}
                            </div>

                        </div>

                        <div className='InputBox'>
                            <div>
                                <label htmlFor="outcomeMeasures">Outcome Measures</label>
                                <input type="text" {...register("outcomeMeasures", { required: false })} className={errors.outcomeMeasures ? 'errorInput' : ""} />
                                {errors.outcomeMeasures && errorMessages()}
                            </div>

                        </div>
                    </CollapsibleSection>

                    {/* Submit Button */}
                    <div className="flex gap-3 justify-center mt-4">
                        <button type="submit" className="flex gap-2 p-2 bg-indigo-700 text-white rounded-md"><FaEdit className='text-xl'/>Update</button>
                    </div>

                </form>
            </div>
        </div>
    );
}
