import React from 'react'
import { Route, Routes} from 'react-router-dom';
import Home from '../Home/Home';
import OPDVisitList from '../OPDVisitList/OPDVisitList';
import AppointmentList from '../AppointmentList/AppointmentList';
import TreatmentsList from '../TreatmentsList/TreatmentsList';
import PatientView from '../PatientView/PatientView';
import ReceiptView from '../ReceiptView/ReceiptView';
import ChangePwd from '../ChangePwd/ChangePwd';
import MyProfile from '../MyProfile/MyProfile';
import CaseList from '../CaseList/CaseList';
import AssesmentFormView from '../AssesmentFormView/AssesmentFormView';

export default function AppRoutes() {
  return (
    <div>
     <Routes>
      <Route path='/' element={<Home/>}/>
       <Route path='/changePwd' element={<ChangePwd />} />
      <Route path='/myProfile' element={<MyProfile />} />
      <Route path="/patientview/:patientId/:visitId" element={<PatientView/>}/>
      <Route path='/caseslist' element={<CaseList/>}/>
      <Route path='/casesView/:id' element={<AssesmentFormView/>}/>
      <Route path='/appointmentlist' element={<AppointmentList/>}/>
      <Route path='/opdView/:id' element={<ReceiptView/>}/>
      <Route path='/ReceiptView/:id' element={<ReceiptView/>}/>
      </Routes>
    </div>
  )
}
