import React from 'react';
import { errorNotifier, successNotifier } from '../../util/notifications';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { request } from '../../util/fetchAPI';

export default function ChangePwd() {
  const { user } = useSelector((state) => state.auth);
  const {
    register,
    handleSubmit,
    watch,
    reset,
    setError,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm();

  const newPwd = watch('newPwd');
  const confirmPwd = watch('confirmPwd');

  const onSubmit = async (data) => {
    try {
      if (data.newPwd !== data.confirmPwd) {
        setError('confirmPwd', { message: 'Passwords do not match' });
        return;
      }
      clearErrors('confirmPwd');

      const response = await request(`/user/changePwd/${user._id}`, 'PUT', {
        'Content-Type': 'application/json',
      }, data);

      if (response.status === 200) {
        successNotifier(response.data.message);
        reset();
      } else {
        errorNotifier(response.data.message);
      }
    } catch (error) {
      console.error(error);
      errorNotifier('Unexpected error occurred');
    }
  };

  const togglePasswordVisibility = () => {
    const inputs = document.querySelectorAll('#newPwdInput, #confirmPwdInput');
    inputs.forEach((input) => {
      input.type = input.type === 'password' ? 'text' : 'password';
    });
  };

  return (
    <div className="flex flex-col justify-center items-center w-[100%]">
      <ToastContainer />
      <div className='Heading'>
                Change Password
            </div>
      <div className="flex justify-center w-[80%]">
        <form onSubmit={handleSubmit(onSubmit)} className="forms">
          <div className="InputBox">
            <div>
              <label>Old Password</label>
              <input
                className="form-control p-2"
                type="password"
                {...register('password', { required: 'Old Password is required' })}
              />
              {errors.password && <span className="text-red-600">{errors.password.message}</span>}
            </div>
          </div>

          <div className="InputBox">

          <div>
              <label>New Password</label>
              <input
                id="newPwdInput"
                className="form-control p-2"
                type="password"
                {...register('newPwd', { required: 'New Password is required' })}
              />
              {errors.newPwd && <span className="text-red-600">{errors.newPwd.message}</span>}
            </div>
            <div>
              <label>Confirm Password</label>
              <input
                id="confirmPwdInput"
                className="form-control p-2"
                type="password"
                {...register('confirmPwd', { required: 'Please confirm your password' })}
              />
              {errors.confirmPwd && (
                <span className="text-red-600">{errors.confirmPwd.message}</span>
              )}
            </div>
          </div>

          <div className="pt-2">
            Show Password
            <input
              type="checkbox"
              className="form-check-input ml-1"
              id="gridCheck"
              onClick={togglePasswordVisibility}
            />
          </div>

          <div className="Button">
            <button className="SubButton" disabled={isSubmitting}>
              {isSubmitting ? (
                <>
                  Please wait
                  <Spin className="text-white font-bold ml-2" indicator={<LoadingOutlined spin />} />
                </>
              ) : (
                'Submit'
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
