import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { logout } from "../../redux/authSlice"
import { useNavigate } from 'react-router-dom'
import { IoPersonSharp, IoMail } from "react-icons/io5";
import { IoPersonCircle } from "react-icons/io5";
import { FaBell } from "react-icons/fa";
import { CgProfile } from "react-icons/cg";
import { IoMdClose } from "react-icons/io";
import { MdPassword } from "react-icons/md";
import { IoLogOut } from "react-icons/io5";
import './Header.css'


export default function Header() {

  const [showModel, setShowModel] = useState(false)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);

  const handleLogout = () => {
    dispatch(logout());
    navigate("/login");
  };

  return (
    <div className='Header'>

      <div className='leftHeader'>
        <h1 className='flex justify-center items-center mb-4 text-4xl font-extrabold leading-none tracking-tight min-w-52 p-2 bg-white rounded-lg cursor-pointer'>Smart Clinic - <span className='text-lime-500'>CONSULTANT Panel</span></h1>
      </div>

      <div className='rightHeader'>
        <IoMail className='text-2xl font-bold text-blue-800' />
        <FaBell className='text-2xl font-bold text-blue-800' />
        <span onClick={() => setShowModel((prev) => !prev)}>  <IoPersonSharp className='text-2xl font-bold text-blue-800' /></span>
        {showModel &&
          <div className='absolute m-3 p-3 top-0 right-0 w-fit h-fit bg-white shadow-xl rounded-lg text-black cursor-pointer z-50'>
            <IoMdClose className='absolute right-1 m-1 hover:text-red-700' onClick={() => setShowModel((prev) => !prev)} />
            <ul className='flex flex-col gap-2 mt-7 text-lg'>
              <li className='flex gap-1 p-2 rounded-md w-full hover:bg-[#C0C0C0] hover:text-indigo-700'
                onClick={() => {
                  navigate("/myProfile");
                  setShowModel(false)
                }}><IoPersonCircle className='text-3xl ml-2' />My Profile </li>
                <li className='flex gap-1 p-2 rounded-md w-full hover:bg-[#C0C0C0] hover:text-indigo-700'
                onClick={() => {
                  navigate("/changePwd");
                  setShowModel(false)
                }}><MdPassword className='text-3xl ml-2' />Change Password </li>
               <li className='flex gap-1 p-2 rounded-md w-full bg-[#C0C0C0] text-red-700 hover:text-red-800 hover:px-3 transition-all duration-300 ease-in-out'
                onClick={() => { handleLogout(); setShowModel(false) }}><IoLogOut className='text-3xl ml-2' />Logout </li>
            </ul>
          </div>
        }
      </div>
    </div>
  )
}
