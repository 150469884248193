import React from 'react'
import { NavLink } from 'react-router-dom';
import { IoMdHome } from "react-icons/io";
import { FaClipboardList, FaStethoscope, FaHospitalUser } from "react-icons/fa";
import { FaPersonCirclePlus } from "react-icons/fa6";
import { PiListNumbersFill } from "react-icons/pi";
import { IoReceiptSharp } from "react-icons/io5";
import { FaBed } from "react-icons/fa";
import './SideBar.css'

export default function SideBar() {

  const navItems = [
    { path: "/", icon: <IoMdHome />, label: "Home" },
    { path: "/caseslist", icon: <PiListNumbersFill />, label: "Cases List" },
    { path: "/appointmentlist", icon: <PiListNumbersFill />, label: "Appointment List" },
  ];

  return (
    <div className='SideContainer'>
      <nav>
        <ul>
          {navItems.map((item, index) => (
              <NavLink key={index} to={item.path} className={({ isActive }) => (isActive ? "Clicked" : "")}>
                  <li className='flex justify-start items-center text-base'>
                  <span>{item.icon}</span>
                  <span>{item.label}</span>
                  </li>
              </NavLink>
          ))}
        </ul>
      </nav>
    </div>
  )
}
