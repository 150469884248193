import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

// Redux
import { useDispatch } from "react-redux";
import { login } from "../../redux/authSlice";

// API
import { request } from "../../util/fetchAPI";

// Hooks
import { errorNotifier } from "../../util/notifications";
import { ToastContainer } from "react-toastify";

// Icons
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { LoadingOutlined } from "@ant-design/icons";

// Images
import Logo from "../../assets/Logo.jpg";

// Styles
import "react-toastify/dist/ReactToastify.css";
import { Spin } from "antd";
import "./Login.css";

export default function Login() {
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => setShowPassword((prev) => !prev);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      moduleName: process.env.REACT_APP_MODULE_NAME || "doctor",
    },
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSubmit = async (formData) => {
    try {
      const { data, status } = await request(
        "/auth/userLogin",
        "POST",
        { "Content-Type": "application/json" },
        { ...formData },
      );

      if (status !== 200) {
        errorNotifier(data.message || "Login Failed");
      } else {
        dispatch(login(data));
        navigate("/");
      }
    } catch (error) {
      console.error(error);
      errorNotifier("Unexpected Error Occurred");
    }
  };

  return (
    <div>
      <ToastContainer />
      <div className="min-h-screen w-full flex justify-center items-center bg-slate-50">
        <div className="flex flex-col items-center w-11/12 max-w-lg p-6 border-2 border-gray-500 rounded-lg shadow-lg bg-white">
          <h2 className="text-center text-2xl font-bold mb-4">Login to Your Account</h2>
          <img src={Logo} alt="Logo" className="w-72 h-36 mb-4" />

          <div className="mb-5">
              <p className="mx-auto w-fit text-center text-lg text-black">
                For any query feel free to contact us.
              </p>
              <p className="flex gap-2 mx-auto w-fit text-center text-lg text-black">
                Email : <p className="text-indigo-900 underline italic">m.arslan.soft.solution@gmail.com</p>
              </p>
              <p className="flex gap-2 mx-auto w-fit text-center text-lg text-black">
                Contact : <p className="text-indigo-900 underline italic">0317-1505390</p>
              </p>
            </div>

          <form onSubmit={handleSubmit(onSubmit)} className="w-full space-y-4">
            {/* User ID Field */}
            <div>
              <label className="block text-sm font-medium text-gray-900">User ID</label>
              <input
                type="text"
                {...register("userId", { required: "User ID is required" })}
                className={`block w-full rounded-md border-2 p-2 text-gray-900 shadow-sm ${
                  errors.userId ? "border-red-500" : "border-gray-300"
                }`}
                placeholder="Enter User ID"
              />
              {errors.userId && <p className="text-red-500 text-sm">{errors.userId.message}</p>}
            </div>

            {/* Password Field */}
            <div className="relative">
              <label className="block text-sm font-medium text-gray-900">Password</label>
              <input
                type={showPassword ? "text" : "password"}
                {...register("password", { required: "Password is required" })}
                className="block w-full px-3 py-2 rounded-md border-2 text-gray-900 shadow-sm border-gray-300 focus:ring-indigo-600 focus:border-indigo-600"
                placeholder="Enter Password"
              />
              <span
                className="absolute inset-y-0 right-3 flex items-center cursor-pointer mt-5"
                onClick={togglePasswordVisibility}
              >
                {showPassword ? <FaEyeSlash className="text-gray-500 text-lg" /> : <FaEye className="text-gray-500" />}
              </span>
              {errors.password && <p className="text-red-500 text-lg">{errors.password.message}</p>}
            </div>

            {/* Submit Button */}
            <button
              type="submit"
              className="w-full bg-indigo-900 text-white py-2 rounded-md"
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <>
                  Please wait <Spin className="ml-2" indicator={<LoadingOutlined spin />} />
                </>
              ) : (
                "Login"
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
