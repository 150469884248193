import React, { useEffect } from 'react';
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux';
import { logout } from './redux/authSlice';
import { Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import Header from './components/Header/Header';
import SideBar from './components/SideBar/SideBar';
import Contents from './components/Contents/Contents';
import Login from './components/Login/Login';
import './App.css';
import { request } from './util/fetchAPI';

function App() {
  const { user } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
  const navigate = useNavigate();
  // Function to check token validity
  const checkTokenValidity = async () => {
    try {
      const {data, status} = await request("/auth/checkTokenValidation","GET");
      if(status === 401){
        const realseToken = await request("/auth/logout", "POST", { "Content-Type": "application/json" });
          if (realseToken.status !== 200) {
            console.log("Error in logging out");
          }
          dispatch(logout());
          navigate("/login");
      }
    } catch (error) {
          console.log(error)
    }
  };

  useEffect(() => {
    checkTokenValidity();
  }, []);
  return (
    <div>
      <Routes>
        {/* Public Route */}
        <Route path="/Login" element={user ? <Navigate to="/" /> : <Login />} />
      </Routes>

      {!user
        ?
        <Navigate to={"/Login"} />
        :

        <div className="App">
          <Header />
          <div className='MainSetting'>

            <div className='SideBar'>
              <SideBar />
            </div>

            <div className='Content'>
              <Contents />
            </div>

          </div>
        </div>
      }

    </div>
  );
}

export default App;

