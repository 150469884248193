import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import useAppointmentList from '../../hooks/useAppointmentList';
import { ToastContainer } from 'react-toastify';
import { errorNotifier, successNotifier } from '../../util/notifications';

import { request } from '../../util/fetchAPI';
import { useSelector } from 'react-redux';
import { IoMdRefreshCircle } from "react-icons/io";
import "./AppointmentList.css";


export default function AppoinmentList() {
  const { user } = useSelector((state) => state.auth);
  const { register, watch } = useForm({
    defaultValues: {
      dateFrom: new Date(new Date().setMonth(new Date().getMonth() - 1)).toISOString().split('T')[0],
      dateTo: new Date().toISOString().split('T')[0]// Set today's date as default
    },
  });
  const { isLoading, appointmentData, fetchAppointments } = useAppointmentList();
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  // Effect to filter patients based on the search term
  useEffect(() => {
    setFilteredData(appointmentData);
    const filterData = appointmentData.filter((Data) =>
      Data.bookingId.toLowerCase().includes(searchTerm.toLowerCase()) ||
      Data.patientName.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredData(filterData);
  }, [searchTerm, appointmentData]);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const deleteHandler = async (id) => {
    try {
      const data = await request(`/client/deleteClientBy/${id}`, "DELETE");
      if (data.status === 200) {
        successNotifier(data.data.message);
      } else {
        errorNotifier(data.data.message);
      }
    } catch (error) {
      console.log(error);
      errorNotifier("Unexpected Error");
    }
  };

  const dateFrom = watch("dateFrom");
  const dateTo = watch("dateTo");

  useEffect(() => {
    if (dateFrom && dateTo) {
      fetchAppointments(user.employeeId, dateFrom, dateTo); // Fetch appointments only when both dates are defined
    }
  }, [dateFrom, dateTo]);

  return (

    <section className="bg-gray-50 w-[100%] h-[100vh] overflow-auto dark:bg-gray-900 p-3 sm:p-5 ">
      <ToastContainer />
      <h1 className='mb-4 text-2xl font-extrabold leading-none tracking-tight'>Appoinment Of The Day</h1>
      <div className="mx-auto max-w-screen-xl px-4 lg:px-12">
        <div className="bg-white dark:bg-gray-800 relative shadow-md sm:rounded-lg overflow-hidden">
          <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-4">
            <div className="w-full md:w-1/2">
              <div className="flex items-center">
                <label className="sr-only">Search</label>
                <div className="relative w-full">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  </div>
                  <input
                    type="text"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:border-primary-500 block w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    placeholder="Search"
                    onChange={handleSearch}
                  />
                </div>
              </div>

            </div>
            <button
              type="button"
              className="flex items-center justify-center text-white bg-indigo-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800"
              onClick={() => fetchAppointments(user.employeeId, dateFrom, dateTo)}
            >
              <IoMdRefreshCircle className='mr-2 text-xl' />
              Refresh
            </button>

          </div>
          <div className='InputBox p-2'>
            <div>
              <label htmlFor="contact">Date From</label>
              <input type="date"  {...register('dateFrom')} defaultValue={new Date().toISOString().split('T')[0]} />
            </div>
            <div>
              <label htmlFor="contact">Date To</label>
              <input type="date"  {...register('dateTo')} defaultValue={new Date().toISOString().split('T')[0]} />
            </div>
          </div>

          <div className="overflow-x-auto">
            {isLoading ? (
              <div className='loaderContainer'>
                <span className="loader"></span>
              </div>
            ) : (
              <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" className="px-4 py-3">#</th>
                    <th scope="col" className="px-4 py-3">Booking Id</th>
                    <th scope="col" className="px-4 py-3">Patient Name</th>
                    <th scope="col" className="px-4 py-3">Phone Number</th>
                    <th scope="col" className="px-4 py-3">Slot</th>
                    <th scope="col" className="px-4 py-3">Dated</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData && filteredData?.map((data, i) => (
                    <tr key={data._id} className="border-b dark:border-gray-700">
                      <td className="px-4 py-3">{++i}</td>
                      <th scope="row" className="px-4 py-3 font-medium text-gray-900 whitespace-nowrap dark:text-white">{data.prefix} - {data.bookingId}</th>
                      <td className="px-4 py-3">{data.patientName}</td>
                      <td className="px-4 py-3">{data.contact}</td>
                      <td className="px-4 py-3">{data.slotTime}</td>
                      <td className="px-4 py-3">{new Date(data.bookingDate).toDateString()}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </section>

  );
}
